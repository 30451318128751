$charts-container-height: 365px;

.tasks.index {
  #charts {
    &.single-row {
      min-height: $charts-container-height;
    }
    &.double-row {
      min-height: $charts-container-height * 2;
    }
  }
}

.lpa-example-link {
  color: $gray-600;
}

#lpa_example_form,
.lpa-example-widget {
  .colored-stripe {
    width: 5px;
    margin-left: -22px;
    margin-right: 22px;
    margin-bottom: 10px;
    margin-top: 5px;
    flex-shrink: 0;
    max-height: 75px;
    &.corrections { margin-left: -15px; }

    &.bg-perfect      { background-color: #007D1B }
    &.bg-excellent    { background-color: #34C759 }
    &.bg-good         { background-color: #FFCC00 }
    &.bg-acceptable   { background-color: #FF9500 }
    &.bg-unacceptable { background-color: #FF3B30 }
  }
  .lpa-media {
    h4 {
      margin-bottom: 0px !important;
      a {
        font-size: $font-size-base;
      }
    }
    p, span {
      font-size: $small-font-size;
    }
    .output-media__image-container--responsive {
      display: block !important;
      padding-right: 0px !important;
      margin-right: 0px !important;
      width: 75px !important;
      flex: 0 0 auto;
      img {
        width: 75px;
      }
    }
    & > .row {
      margin-right: 0px;
    }
  }
}

#lpa_example_form {
  .bg-gray {
    background: $dashboard-template-bg-color;
    small {
      color: $gray-600;
    }
  }
  .opacity-50 {
    opacity: 0.5;
  }
  trix-toolbar .trix-button-row {
    flex-wrap: wrap;
  }
  .trix-content {
    min-height: 300px !important;
    resize: vertical !important;
    overflow-y: auto;
  }
  .trix-button--icon {
    width: 30px;
  }
  .trix-button-group:not(:first-child) {
    margin-left: 10px !important;
  }
}
